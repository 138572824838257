export const ORIGINS = {
  BIO: "Bio",
  ABC: "Aubrac",
  AOP: "AOP",
  BBC: "Bleu blanc coeur",
  ELE: "Equilibré",
  FCS: "Français",
  LOC: "Fait maison",
  FMN: "Menu malin",
  IGP: "IGP",
  PFA: "Porc fermier d'Auvergne",
  POF: "Porc français",
  VTN: "Végétarien"
};

export const PRODUCT_TYPES = {
  STARTER: "ENT",
  MAIN: "PLA",
  DESSERT: "DES",
  DRINK: "BOI",
  SIDE: "ACC",
  // FORMULA: "FOR",
  // DAIRY: "LAI",
  CHEESE: "FRO"
  // OTHER: "AUT"
};

export const PRODUCT_TYPES_NAMES = {
  [PRODUCT_TYPES.STARTER]: "Entrée",
  [PRODUCT_TYPES.MAIN]: "Plat",
  [PRODUCT_TYPES.DESSERT]: "Dessert",
  [PRODUCT_TYPES.CHEESE]: "Fromage",
  // [PRODUCT_TYPES.DAIRY]: "Laitage",
  [PRODUCT_TYPES.DRINK]: "Boisson",
  [PRODUCT_TYPES.SIDE]: "Accompagnement"
  // [PRODUCT_TYPES.OTHER]: "Autre"
  // [PRODUCT_TYPES.FORMULA]: "Spécial : prix formule"
};
